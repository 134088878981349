import React from "react"
import { useTranslation } from "react-i18next"

const SchoolProgrammesSection = props => {
  const school = props.school
  const programs =
    school && school.educationalPrograms &&
    school.educationalPrograms.length
      ? school.educationalPrograms
      : []
  const optionsStr =
    school &&
    school.dataContext &&
    school.dataContext.settings &&
    school.dataContext.settings.additionalOptionsResolved
      ? school.dataContext.settings.additionalOptionsResolved
      : ""


  const options = optionsStr ? optionsStr.split(",") : []
  const { t } = useTranslation()
  return (
    <>
      <div className="white-block educational-prog-wrapper">
        <div className="prog-data-wrapper f-js container">
          {programs.length ? (
            <div>
              <div className="row">
                <div className="key-data-title col-12">
                  {t("school.keydata.edProgrammes")}
                </div>
              </div>

              <div className="programmes-row1 row">
                {programs.map((p) => (
                  <div
                    key={p.educationalProgramId}
                    className="program-item program1 col-sm-6 col-md-4 col-lg-3"
                  >
                    <div className="white-back">
                      <div className="key-data-item-title">{p.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {props.school.isSummer && options.length ? (
            <div>
              <div className="row">
                <div className="key-data-title col-12">
                  {t("school.keydata.adOptions")}
                </div>
              </div>
              <div className="programmes-row1 row">
                {options.map((p, i) => (
                  <div
                    key={i}
                    className="program-item program1 col-sm-6 col-md-4 col-lg-3"
                  >
                    <div className="white-back">
                      <div className="key-data-item-title">{p}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SchoolProgrammesSection
