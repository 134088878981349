import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import ArrowRightIconRed from "../../svg/arrow-right-red.inline.svg"
import SchoolStationSection from "./SchoolStationSection"

import { Link } from "gatsby"

const SchoolStudentStoriesSection = props => {
  const stories = props.school.dataContext.stories || []

  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  const [studentStoryPresent, setStudentStoryPresent] = useState(false)

  useEffect(() => {
    setStudentStoryPresent(stories.length <= 0)
  })

  const isStoryPresent = studentStoryPresent ? "student-story-is-present" : ""

  return (
    <>
      {stories != null && (
        <div
          className={`white-block student-stories-block position-relative container px-6 ${isStoryPresent}`}
        >
          <SchoolStationSection school={props.school} />
          <div className="student-story-quote-wrapper container">
            {stories.map(story => (
              <div key={story.id} className="student-stories-wrapper f-js">
                <div className="student-stories-title">
                  {t("school.studentStories.studentStoriesTitle")}
                </div>
                <div className="student-story">{story.quote}</div>

                <Link to={`/${currentLang}/article/${story.resolvedPath}`}>
                  <div className="f-js curs-P">
                    <div className="full-story-line">
                      {t("school.studentStories.fullStory")}
                    </div>
                    <ArrowRightIconRed className="arrow_right-red" />
                  </div>
                </Link>

                <div className="student-profile f-js f-ac">
                  <div
                    className="student-photo"
                    style={{
                      background: `url(${story.resolvedAuthorPhotoUrl}) center no-repeat`,
                    }}
                  ></div>
                  <div className="student-info f-js">
                    <div className="student-name">{story.authorName}</div>
                    <div className="student-age">{story.authorDescription}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default SchoolStudentStoriesSection
