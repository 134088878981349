import React from "react"
import Slider from "react-slick"
import SchoolStationSection from "./SchoolStationSection"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, { Pagination, Navigation } from "swiper"
// import "swiper/swiper.scss"
// import "swiper/components/pagination/pagination.scss"

SwiperCore.use([Pagination, Navigation])

const SchoolGallerySection = props => {
  const { t, i18n } = useTranslation()

  const currentLang = i18n.language
  const schoolPathName = props.school.resolvedPath

  const redirectSelect = () => {
    if (props.school.isSummer) {
      return `/${currentLang}/summer-schools/${schoolPathName}/gallery`
    } else {
      return `/${currentLang}/school/${schoolPathName}/gallery`
    }
  }
  let slides = []

  for (let i = 0; i < 5; ++i) {
    if (props.media[i] != null) {
      slides[i] = props.media[i]
    } else {
      slides = props.media
    }
  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    dotsClass: "upcoming-events-lines-wrapper",
    focusOnSelect: true,
  }

  const thePagination = React.useRef(null)
  return (
    <div className="gallery">
      <div className="gallery-wrapper container">
        <div className="gallery-info-block">
          <div className="f-jb f-ac mB-50 gallery-header row">
            <div className="upcoming-events-header-title col-4 col-md-4 col-lg-4">
              {t("school.gallery.galleryTitle")}
            </div>
            <Link
              to={redirectSelect()}
              className="col-4 col-md-4 col-lg-4 d-flex justify-content-end"
            >
              <div className="upcoming-events-header-view">
                {t("school.gallery.viewPhotos")}
              </div>
            </Link>
          </div>
          <div className="row">
            <Swiper
              grabCursor={true}
              slidesPerView={1}
              spaceBetween={180}
              className="mySwiper col-12"
              navigation={{
                nextEl: ".hand-book-slider-control-button.slider-right",
                prevEl: ".hand-book-slider-control-button.slider-left",
                // prevEl: navigationPrevRef.current,
                // nextEl: navigationNextRef.current,
              }}
              pagination={{
                el: ".events-pagination",
                clickable: true,
              }}
            >
              {slides &&
                slides.map((el, index) => {
                  if (!el.contentType) {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className="school-item-line-gallery-block"
                        >
                          <iframe
                            type="text/html"
                            key={el.id}
                            title="YouTube video player"
                            frameBorder="0"
                            allowFullScreen
                            style={{ height: "100vh", width: "100vh", maxWidth: "800px" }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src={el.resolvedFilePath}
                          ></iframe>
                          <div className="school-gallery-description-line">
                            {el?.description}
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  } else {
                    return (
                      <SwiperSlide key={index} className="w-100">
                        <div
                          key={index}
                          className="school-item-line-gallery-block"
                        >
                          <img key={el.id} src={el.resolvedFilePath} alt="" />
                          <div className="school-gallery-description-line">
                            {el?.description}
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  }
                })}
            </Swiper>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="hand-book-slider-control-button slider-left col-2 mt-2" />
              <div
                ref={thePagination}
                className="events-pagination upcoming-events-lines-wrapper col-md-8 col-lg-8 d-flex justify-content-center gap-4 mt-4"
              ></div>
              <div className="hand-book-slider-control-button slider-right col-2 mt-2" />
            </div>
          </div>
          {!props.school.isSummer ? (
            <div className="row d-flex justify-content-center">
              <div className="quote-wrapper col-4">
                <div className="latin-quote">{props.school?.quoteGeneral}</div>
                <div className="eng-quote text-center">
                  {props.school.quote}
                </div>
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center">
              <div className="quote-wrapper col-4">
                <div className="latin-quote"></div>
                <div className="eng-quote text-center"></div>
              </div>
            </div>
          )}
        </div>

        {/* <SchoolStationSection school={props.school} /> */}
      </div>
    </div>
  )
}
export default SchoolGallerySection
