import React, { useEffect, useState } from "react"
import axios from "../../http/axios"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolKeyDataSection from "../../sections/schools/SchoolKeyDataSection"
import SchoolProgrammesSection from "../../sections/schools/SchoolProgrammesSection"
import SchoolGallerySection from "../../sections/schools/SchoolGallerySection"
import SchoolStudentStoriesSection from "../../sections/schools/SchoolStudentStoriesSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"

import SideNavMenuSection from "../../sections/SideNavMenuSection"
import UserSideNavSection from "../../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../../sections/login_signup_side_navs/SchoolSideNavSection"

import SideNavRequestMeetingSection from "../../sections/SideNavRequestMeetingSection"
import SideNavSendMessageSection from "../../sections/SideNavSendMessageSection"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"
import Layout from "../../components/Layout"

const SchoolPage = props => {
  useEffect(() => {
    axios.post(
      `/${props.pageContext.school.isSummer ? "summer-schools" : "schools"}/${
        props.pageContext.school.id
      }/visits`
    )
  }, [])

  const schoolsSelect = props.pageContext.schools
    ? props.pageContext.schools
    : props.pageContext.summerSchools
  return (
    <Layout
      wrapperClass={"school-item"}
      pageTitle={`${props.pageContext.school.name} | Smart Admissions Service`}
      keywordsContent={props.pageContext.school.metaKeywords}
      descriptionContent={props.pageContext.school.metaDescription}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        state1="active"
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        school={props.pageContext.school}
        isMainSchoolTab={true}
      />
      <SchoolKeyDataSection
        school={props.pageContext.school}
        schools={schoolsSelect}
      />
      <SchoolProgrammesSection school={props.pageContext.school} />
      <SchoolGallerySection
        school={props.pageContext?.school}
        media={props.pageContext?.school?.dataContext.media || []}
      />
      <SchoolStudentStoriesSection school={props.pageContext.school} />

      <SchoolFooterSection
        school={props.pageContext.school}
        schools={schoolsSelect}
      />

      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection />

      <SideNavRequestMeetingSection school={props.pageContext.school} />
      <SideNavSendMessageSection school={props.pageContext.school} />
      <SideNavApplyDocsSection school={props.pageContext.school} />
    </Layout>
  )
}

export default SchoolPage
