import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import { useTranslation } from "react-i18next"

const { addItemLocalStorage } = require("../../scripts/Utils")

import axios from "../../http/axios"
import { getItemLocalStorage } from "../../scripts/Utils"
import { useCurrentUser } from "../../components/CurrentUserContext"

import ArrowLeftIcon from "../../svg/arrow-left.inline.svg"
import ArrowRightIcon from "../../svg/arrow-right.inline.svg"

import ArrowRightIconDark from "../../svg/arrow-right-dark.inline.svg"

import CompareIcon from "../../svg/comp-icon.inline.svg"
import FavouritesIcon from "../../svg/heart-icon.inline.svg"

import LetterIcon from "../../svg/letter-icon.inline.svg"

import PhoneIcon from "../../svg/phone-icon.inline.svg"

import InterestedIcon from "../../svg/interested-icon.inline.svg"

import SchoolTextSection from "./SchoolTextSection"

import { useSidemenu } from "../../components/SidemenuContext"

const SchoolKeyDataSection = props => {
  const {
    toggleRequestMeetingMenu,
    toggleApplyDocsMenu,
    toggleSendMessageMenu,
  } = useSidemenu()
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language
  const [isCompared, setIsCompared] = useState()
  const [isFavorite, setIsFavorite] = useState()
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    if (!props.school.isSummer) {
      axios
        .get(`/schools/${props.school.id}?lang=${currentLang}`)
        .then(res => {
          setIsFavorite(res.data.isFavorite)
        })
        .catch()
      const getLocalStorageItems = getItemLocalStorage("compare")
      const checkAddItem = getLocalStorageItems.find(
        a => a.id === props.school.id
      )
      if (checkAddItem) {
        setIsCompared(true)
      } else {
        setIsCompared(false)
      }
    } else {
      axios
        .get(`/summer-schools/${props.school.id}?lang=${currentLang}`)
        .then(res => {
          setIsFavorite(res.data.isFavorite)
        })
        .catch()
      const getSummerLocalStorageItems = getItemLocalStorage("compare-summer")
      const checkAddSummerItem = getSummerLocalStorageItems.find(
        a => a.id === props.school.id
      )
      if (checkAddSummerItem) {
        setIsCompared(true)
      } else {
        setIsCompared(false)
      }
    }
  }, [])
  const school = props.school
  const settings = school.dataContext.settings || {}

  let manager
  if (school.dataContext.managers) {
    school.dataContext.managers.forEach(m => {
      if (m.isMain === true) {
        manager = m
      }
    })
  }

  const schools = props.schools.sort((a, b) => a.name?.localeCompare(b.name))
  const currentIndex = schools.findIndex(s => s.id === school.id)
  const isLastSchool = school.id === schools[schools.length - 1].id
  const isFirstSchool = school.id === schools[0].id

  const onNextSchool = () => {
    if (isLastSchool) {
      return
    }
    const idToGo = schools[currentIndex + 1].resolvedPath
    if (props.school.isSummer) {
      navigate(`/${currentLang}/summer-schools/${idToGo}`)
    } else {
      navigate(`/${currentLang}/school/${idToGo}`)
    }
  }

  const onPrevSchool = () => {
    if (isFirstSchool) {
      return
    }

    const idToGo = schools[currentIndex - 1].resolvedPath
    if (props.school.isSummer) {
      navigate(`/${currentLang}/summer-schools/${idToGo}`)
    } else {
      navigate(`/${currentLang}/school/${idToGo}`)
    }
  }

  const startDate = settings?.currentDate

  const savedSchoolsList = school.isSummer
    ? "saved-summer-schools"
    : "saved-schools"

  const compareSchoolsList = school.isSummer ? "compare-summer" : "compare"

  const addToFavourites = () => {
    axios
      .post(`/accounts/${currentUser.id}/${savedSchoolsList}`, {
        accountId: currentUser.id,
        schoolId: school.id,
        schoolMode: 0,
      })
      .then(() => {
        setIsFavorite(!isFavorite)
      })
      .catch()
  }

  const removeFromFavourites = () => {
    if (currentUser) {
      axios
        .delete(
          `/accounts/${currentUser.id}/${savedSchoolsList}/${props.school.id}`
        )
        .then(() => {
          setIsFavorite(!isFavorite)
        })
        .catch()
    }
  }

  const addCompareList = () => {
    addItemLocalStorage(props.school, compareSchoolsList)
    setIsCompared(!isCompared)
  }

  function resolveDates() {
    if (settings?.currentDate) {
      return (
        <div
          className="key-data-item-text"
          key={`body`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: settings?.currentDate }}
        />
      )
    }
  }

  const resolvePrevSchoolType = prevSchool => {
    if (prevSchool.isSummer) {
      return (
        <div className="prev-school nav-control-button text-center">
          {t("school.feedback.prevOrg")}
        </div>
      )
    } else {
      return (
        <div className="prev-school nav-control-button text-center">
          {t("school.feedback.prevSchool")}
        </div>
      )
    }
  }

  const resolveFavouritesListControlButtons = () => {
    if (currentUser) {
      return (
        <div>
          {!isFavorite ? (
            <div
              className="add-fav-wrapper f-jc f-ac curs-P"
              onClick={addToFavourites}
            >
              <FavouritesIcon className="add-fav-image" />
              <div className="add-fav-text nav-control-button text-center">
                {t("school.feedback.addFavourites")}
              </div>
            </div>
          ) : (
            <div
              className="add-fav-wrapper f-jc f-ac curs-P"
              onClick={removeFromFavourites}
            >
              <FavouritesIcon className="add-fav-image" />
              <div className="add-fav-text nav-control-button text-center">
                {t("school.feedback.removeFavourites")}
              </div>
            </div>
          )}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const resolveCompareListControlButtons = () => {
    if (currentUser) {
      return (
        <div className="f-jc">
          <CompareIcon className="add-comp-image" />
          {!isCompared ? (
            <div
              onClick={() => addCompareList()}
              className="add-comp-text nav-control-button text-center"
            >
              {t("school.feedback.addCompare")}
            </div>
          ) : (
            <Link to={`/${currentLang}/profile/compare-schools`}>
              <div className="add-comp-text nav-control-button text-center">
                {t("school.feedback.removeCompare")}
              </div>
            </Link>
          )}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  const resolveNextSchoolType = nextSchool => {
    if (nextSchool.isSummer) {
      return (
        <div className="prev-school nav-control-button text-center">
          {t("school.feedback.nextOrg")}
        </div>
      )
    } else {
      return (
        <div className="prev-school nav-control-button text-center">
          {t("school.feedback.nextSchool")}
        </div>
      )
    }
  }

  //SETTINGS

  const resolveSummerSettingsSchoolType = school => {
    if (school.tuitionHoursPerWeekMin || school.tuitionHoursPerWeekMax) {
      return (
        <div className="key-data-item f-js key-item1 col-3">
          <div className="key-data-item-title">
            {t("school.summerKeydata.tuitionHours")}
          </div>
          {school.tuitionHoursPerWeekMin === school.tuitionHoursPerWeekMax && (
            <div className="key-data-item-text">
              {school.tuitionHoursPerWeekMin}
            </div>
          )}
          {school.tuitionHoursPerWeekMin &&
            (!school.tuitionHoursPerWeekMax ||
              school.tuitionHoursPerWeekMax == 0) && (
              <div className="key-data-item-text">
                {school.tuitionHoursPerWeekMin}
              </div>
            )}
          {school.tuitionHoursPerWeekMax &&
            (!school.tuitionHoursPerWeekMin ||
              school.tuitionHoursPerWeekMin == 0) && (
              <div className="key-data-item-text">
                {school.tuitionHoursPerWeekMax}
              </div>
            )}
          {school.tuitionHoursPerWeekMax &&
            school.tuitionHoursPerWeekMin &&
            school.tuitionHoursPerWeekMin !== school.tuitionHoursPerWeekMax && (
              <div className="key-data-item-text">
                {school.tuitionHoursPerWeekMin}–{school.tuitionHoursPerWeekMax}
              </div>
            )}
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSummerSettingsAge = schoolAgeSettings => {
    if (schoolAgeSettings.ageMin) {
      return (
        <div className="key-data-item key-item2 col-3">
          <div className="key-data-item-title">
            {t("school.summerKeydata.age")}
          </div>
          <div className="key-data-item-text">
            {schoolAgeSettings.ageMin}–{schoolAgeSettings.ageMax}{" "}
            {t("school.years")}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSummerSettingsClassSize = schoolClassSettings => {
    if (schoolClassSettings.maxClassSize) {
      return (
        <div className="key-data-item key-item3 col-3">
          <div className="key-data-item-title">
            {t("school.summerKeydata.boardersAge")}
          </div>
          <div className="key-data-item-text">
            {schoolClassSettings.maxClassSize}{" "}
            {t("school.summerKeydata.people")}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSummerSettingsStudentsNumberSize = schoolStudNumberSettings => {
    if (schoolStudNumberSettings.numberOfStudents) {
      return (
        <div className="key-data-item key-item4 col-3">
          <div className="key-data-item-title">
            {t("school.summerKeydata.studentsNumber")}
          </div>
          <div className="key-data-item-text">
            {schoolStudNumberSettings.numberOfStudents}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSummerSettingsDatesSize = date => {
    if (date) {
      return (
        <div className="key-data-item key-item5 col-3">
          <div className="key-data-item-title">
            {t("school.summerKeydata.boardersPercent")}
          </div>

          {resolveDates(date)}
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsSchoolType = schoolType => {
    if (schoolType.schoolTypesResolved) {
      return (
        <div className="key-data-item f-js key-item1 col-3">
          <div className="key-data-item-title">{t("school.keydata.type")}</div>
          <div className="key-data-item-text">
            {settings.schoolTypesResolved}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsAge = schoolAge => {
    if (schoolAge.ageMin) {
      return (
        <div className="key-data-item key-item2 col-3">
          <div className="key-data-item-title">{t("school.keydata.age")}</div>
          <div className="key-data-item-text">
            {schoolAge.ageMin}–{schoolAge.ageMax} {t("school.years")}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsBoardersAge = schoolBoardersAge => {
    if (schoolBoardersAge.boardersAgeMin) {
      return (
        <div className="key-data-item key-item3 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.boardersAge")}
          </div>
          <div className="key-data-item-text">
            {schoolBoardersAge.boardersAgeMin}–
            {schoolBoardersAge.boardersAgeMax} {t("school.years")}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsStudsNumber = schoolStudsNumber => {
    if (schoolStudsNumber.numberOfStudents) {
      return (
        <div className="key-data-item key-item4 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.studentsNumber")}
          </div>
          <div className="key-data-item-text">
            {schoolStudsNumber.numberOfStudents}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsBoardersPercent = schoolBoardersPercent => {
    if (schoolBoardersPercent.boardersAtSchool) {
      return (
        <div className="key-data-item key-item5 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.boardersPercent")}
          </div>
          <div className="key-data-item-text">
            {schoolBoardersPercent.boardersAtSchool}%
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsInternationalStudents = schoolInternationalStudents => {
    if (schoolInternationalStudents.internationalStudents) {
      return (
        <div className="key-data-item key-item6 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.internationalStudents")}
          </div>
          <div className="key-data-item-text">
            {schoolInternationalStudents.internationalStudents}%
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsTeachersRatio = schoolTeachersRatio => {
    if (schoolTeachersRatio.teacherToStudentsRatio) {
      return (
        <div className="key-data-item key-item7 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.teachers")}
          </div>
          <div className="key-data-item-text">
            1:{schoolTeachersRatio.teacherToStudentsRatio}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const resolveSettingsReligion = schoolReligion => {
    if (schoolReligion.religionValue) {
      return (
        <div className="key-data-item key-item8 col-3">
          <div className="key-data-item-title">
            {t("school.keydata.religion")}
          </div>
          <div className="key-data-item-text">
            {schoolReligion.religionValue}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="white-block key-data-block">
      <div className="container">
        <div className="school-panel f-jc f-ac">
          <div className="school-logo-block school-item-king row d-flex justify-content-center">
            <img
              className="school-logo-image col-3"
              src={props.school.resolvedLogoUrl}
              alt={props.school.name}
            />
          </div>
          <div className="nagivation-bar px-5 row d-flex justify-content-center">
            {currentIndex > 0 ? (
              <div
                className={`previous-item-wrapper f-ac ${
                  !currentUser ? "col-md-6 col-lg-6" : "col-lg-3"
                } `}
                style={{ cursor: "pointer" }}
              >
                {schools[currentIndex - 1] && (
                  <div
                    onClick={onPrevSchool}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <ArrowLeftIcon className="arrow_left" />
                    {resolvePrevSchoolType(school)}
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`previous-item-wrapper f-ac ${
                  !currentUser ? "col-md-6 col-lg-6" : "col-lg-3"
                } `}
              ></div>
            )}

            {currentUser && (
              <div className="like-compare-buttons col-lg-6 d-flex justify-content-center">
                {resolveFavouritesListControlButtons()}
                {resolveCompareListControlButtons()}
              </div>
            )}

            {schools.length > currentIndex + 1 ? (
              <div
                className={`next-item-wrapper  f-ac ${
                  !currentUser ? "col-md-6 col-lg-6" : "col-lg-3"
                } d-flex justify-content-end`}
                style={{ cursor: "pointer" }}
              >
                {schools[currentIndex + 1] && (
                  <div
                    onClick={onNextSchool}
                    className="d-flex justify-content-end align-items-center"
                  >
                    {resolveNextSchoolType(school)}

                    <ArrowRightIcon className="arrow_right" />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`next-item-wrapper  f-ac ${
                  !currentUser ? "col-md-6 col-lg-6" : "col-lg-3"
                } d-flex justify-content-end`}
              ></div>
            )}
          </div>

          <div className="feedback-block row d-flex justify-content-center gap-5 ">
            <div className="feedback-item-wrapper advisor-block col-6 col-sm-6 col-lg-5 p-5 mt-5">
              <div className="feedback-block-top-title">
                {t("school.feedback.advisor")}
              </div>
              <div className="feedback-main-info">
                {manager && (
                  <div
                    className="feedback-block-icon advisor-icon"
                    style={{
                      background: `url(${manager.resolvedImageUrl}) center no-repeat`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                )}
                {manager && (
                  <div className="feedback-block-title">
                    {manager.firstname} {manager.lastname}
                  </div>
                )}

                {manager && (
                  <div className="additional-info">{manager.position}</div>
                )}
                <div className="interactivity-wrapper">
                  <div
                    className="f-ac curs-P"
                    onClick={toggleRequestMeetingMenu}
                  >
                    <PhoneIcon className="phone-icon" />
                    <div className="request-meeting">
                      {t("school.feedback.requestMeeting")}
                    </div>
                  </div>
                  <div className="f-ac curs-P" onClick={toggleSendMessageMenu}>
                    <LetterIcon className="letter-icon" />
                    <div className="send-message">
                      {t("school.feedback.sendMessage")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="feedback-item-wrapper application-form-block col-6 col-sm-6 col-lg-5 p-5 mt-5">
              <div className="feedback-block-top-title">
                {t("school.feedback.applicationForm")}
              </div>
              {school.isSummer ? (
                <div className="feedback-main-info">
                  <InterestedIcon className="feedback-block-icon interested-icon" />
                  <div className="feedback-block-title">
                    {t("school.feedback.interestedStudyingSummer")}
                  </div>
                  <div
                    className="interactivity-wrapper curs-P"
                    onClick={toggleApplyDocsMenu}
                  >
                    <div className="apply-doc-text">
                      {t("school.feedback.applyDocsSummer")}
                    </div>
                    <ArrowRightIconDark className="arrow_right" />
                  </div>
                </div>
              ) : (
                <div className="feedback-main-info">
                  <InterestedIcon className="feedback-block-icon interested-icon" />
                  <div className="feedback-block-title">
                    {t("school.feedback.interestedStudying")}
                  </div>
                  <div
                    className="interactivity-wrapper curs-P"
                    onClick={toggleApplyDocsMenu}
                  >
                    <div className="apply-doc-text">
                      {t("school.feedback.applyDocs")}
                    </div>
                    <ArrowRightIconDark className="arrow_right" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="key-data-wrapper f-js container">
        {props.school.isSummer && (
          <div class="row">
            <SchoolTextSection text={school?.aboutCompany} />
            <div class="col-lg-2" />
          </div>
        )}
        <div className="row">
          <div className="key-data-title col-12">
            {t("school.keydata.keyDataTitle")}
          </div>
        </div>
        {props.school.isSummer ? (
          <>
            <div className="key-data-info-list row">
              {resolveSummerSettingsAge(settings)}
              {resolveSummerSettingsClassSize(settings)}
              {resolveSummerSettingsSchoolType(props.school)}
              {resolveSummerSettingsStudentsNumberSize(settings)}
              {resolveSummerSettingsDatesSize(startDate)}
            </div>
          </>
        ) : (
          <div className="key-data-info-list row">
            {resolveSettingsSchoolType(settings)}
            {resolveSettingsAge(settings)}
            {resolveSettingsBoardersAge(settings)}
            {resolveSettingsStudsNumber(settings)}
            {resolveSettingsBoardersPercent(settings)}
            {resolveSettingsInternationalStudents(settings)}
            {resolveSettingsTeachersRatio(settings)}
            {resolveSettingsReligion(settings)}
          </div>
        )}
      </div>
    </div>
  )
}

export default SchoolKeyDataSection
